.homepage {
  text-align: center;
  padding: 20px;
}

@font-face {
  font-family: "Voga";
  src: url(/Users/pietrosaveri/Desktop/pasta-website/CDType_Voga-font-demo_031216/voga.otf) format('otf');
}

@font-face {
  font-family: "Impact";
  src: url(/Users/pietrosaveri/Desktop/pasta-website/src/font/Impact-Font/impact.ttf) format('ttf');
}

body{
  background-color: black;
}

body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(/Users/pietrosaveri/Desktop/pasta-website/src/img/bg03.png);
  background-size: cover;
  background-position: 0px 0px;
  background-repeat: no-repeat;
  opacity: 0.4;
  transform: rotate(0deg);
  z-index: -1;
}

