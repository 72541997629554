.PastaToGrams {
    text-align: center;
    margin-top: -55px;
    color: rgb(221, 180, 117);
    font-family: "Impact";
    font-size: 60px;
}

.text-plates01 {
    color: rgb(255, 236, 208);
    margin-top: -70px;
    text-align: center;
    font-size: 30px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.hover-goBakcbutton01 {
    cursor: pointer;
    background-color: transparent;
    transition: transform 0.2s ease-in-out;
    border: 2px transparent;
    position: fixed;
    right: 30px;
    bottom: 30px;
    height: 30px;
    width: 30px;
}

.home-icon01 {
    color: white;
    height: 35px;
    width: 35px;
}

.hover-goBakcbutton01:hover {
    transform: scale(1.1) translateY(-10px);
}

.choiche_container01{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.flat_plates01 {
    background-color: #525252;
    color: rgb(115, 115, 115);
    padding: 130px 200px;
    border: 5px solid rgb(100, 143, 216);
    margin: 0 100px;
    border-radius: 20px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    background-image: url('/Users/pietrosaveri/Desktop/pasta-website/src/img/flat_plate02.png');
    background-size: cover;
}

.flat_plates01:hover{
    transform: scale(1.1);
    border: 5px solid rgb(10, 145, 55);
}

.flat_plates01.bigger{
    transform: scale(1.1);
    border: 5px solid rgb(10, 145, 55);
}

.soup_plates01 {
    background-color: #525252;
    color: rgb(71, 71, 71);
    padding: 130px 200px;
    margin: 0 100px;
    border: 5px solid rgb(100, 143, 216);
    border-radius: 20px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    background-image: url('/Users/pietrosaveri/Desktop/pasta-website/src/img/soup_plate02.png');
    background-size: cover;
}

.soup_plates01:hover {
    transform: scale(1.1);
    border: 5px solid rgb(10, 145, 55);
}

.soup_plates01.bigger {
    transform: scale(1.1);
    border: 5px solid rgb(10, 145, 55);
}

.InsertPlatesTrueTEXT01 {
    text-align: left;
    padding: 25px;
    margin-top: 25px;
    margin-left: 5px;
    color: rgb(255, 236, 208);
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 15px;
}


